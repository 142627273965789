<template>
  <router-link :to="localizedRoute(`/posts/${post.slug}`)" class="blog-post">
    <div
      class="box-image"
      style="backgroundColor: #ccc;"
      v-lazy:background-image="getPostImage(post)"
    />

    <div class="box-inner">
      <div class="box-subhead" v-if="getCategory(post)">
        {{ getCategory(post) }}
      </div>

      <div class="box-head">
        {{ post.title }}
      </div>

      <div class="box-content" v-html="$options.filters.truncate( post.excerpt, 175, '...' )" />
    </div>
  </router-link>
</template>

<script>
// import PostsIndex from './Index'

export default {
  props: {
    post: Object
  },
  filters: {
    truncate: function (text, length, suffix) {
      return text.length > 0 ? text.substring(0, length) + suffix : ''
    }
  },
  methods: {
    getCategory (post) {
      if (typeof post.categories.edges[0] !== 'undefined') {
        return post.categories.edges[0].node.name;
      }
    },
    getPostImage (post) {
      if (post.featuredImage != null && post.featuredImage.node.sourceUrl != null) {
        return post.featuredImage.node.sourceUrl
      }
    }
  }
}
</script>
