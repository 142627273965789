<template>
  <section class="is--promo">
    <div class="container">
      <div class="row">
        <div class="col-xs-7">
          <div class="promo-content">
            <h2>
              Zaun24 ist Testsieger!<br>
              Über 100.000 zufriedene Kunden
            </h2>
            <p>Egal ob Doppelstabmatten- oder Schmuckzaun, Sichtschutzzaun oder Gabione, kleines Gartentörchen oder riesige Einfahrtstoranlage mit elektrischem Antrieb. Bei uns findest Du alles rund ums Thema ZAUN - Zaunkompetenz seit 2008</p>
            <div class="badges">
              <router-link
                :to="localizedRoute('/tuev-zertifizierung')"
                class="badge badge-tuev"
              >
                <img
                  v-lazy="'/assets/logo/seal-SC45376-decoga-gmbh-tuev-gepruefter-online-shop-2023-zw.svg'"
                  alt="TÜV Saarland - geprüfter Onlineshop"
                  width="170px"
                  height="90px"
                >
              </router-link>
              <EkomiSeal />
              <a
                href="https://www.trustedshops.de/bewertung/info_XD28B09EDC76335A47FB54A25E74BA82B.html"
                target="_blank"
                class="badge badge-ts"
              >
                <img
                  v-lazy="'/assets/logo/logo-trustedshops.svg'"
                  alt="Trusted Shops"
                  width="70px"
                  height="70px"
                >
              </a>
            </div>
          </div>
        </div>

        <div class="col-xs-5">
          <div class="promo-image">
            <img
              class="badge badge-ntv"
              v-lazy="'/assets/logo/seal-ntv-2021.svg'"
              alt="Zaun24 ist Testsieger - Deutschlands beste Onlineshops 2021"
              width="190px"
              height="190px"
            >
            <img
              class="badge badge-focus"
              v-lazy="'/assets/logo/beste-haendler-2021.svg'"
              alt="Zaun24 mit höchster Weiterempfehlungsquote lt. Focus Money"
              width="165px"
              height="auto"
            >
            <img
              class="fence"
              v-lazy="'/assets/content/promo-fence.webp'"
              alt="Einstabmattenzaun bei ZAUN24 kaufen"
              width="515px"
              height="300px"
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import EkomiSeal from './EkomiSeal'

export default {
  name: 'PromoZaun24',
  components: {
    EkomiSeal
  }
}
</script>
