<template>
  <section class="is--slider-blog container-full">
    <div class="background">
      <div class="container">
        <h2 class="section-head">
          <img
            v-lazy="'/assets/logo/logo-zaun24-ratgeber.svg'"
            alt="Zaun24 | Ratgeber"
            width="245px"
            height="40px"
          >
        </h2>

        <div class="blog-wrap">
          <post-tile v-if="featuredPost" :key="featuredPost.id" :post="featuredPost" />
          <post-tile v-for="(post) in postList.slice(0,3)" :key="post.id" :post="post" />
        </div>

        <div class="blog-actions">
          <a href="/posts" class="btn btn-primary">
            Mehr entdecken
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PostsIndex from '../../../posts/Index'

export default {
  mixins: [PostsIndex]
}
</script>
