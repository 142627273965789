<template>
  <div class="blog-page blog-page-posts-index">
    <div class="blog-page--hero">
      <h1 class="blog-page--page-title">
        <img v-lazy="'/assets/logo/logo-zaun24-ratgeber.svg'" alt="ZAUN24 | Ratgeber" width="245px" height="40px">
      </h1>
    </div>

    <div v-if="posts && posts.length > 0">
      <div class="container">
        <div class="blog-page--preview-large blog-page--preview-large-hero-merge" :style="getPostImage(featuredPost)">
          <div class="blog-page--preview-large-inner">
            <h4 class="blog-page--preview-category">
              {{ getCategory(featuredPost) }}
            </h4>

            <h2 class="blog-page--preview-title">
              <router-link :to="localizedRoute(`/posts/${featuredPost.slug}`)">
                <span v-html="featuredPost.title" />
              </router-link>
            </h2>

            <p class="blog-page--preview-excerpt" v-html="$options.filters.truncate( featuredPost.excerpt, 355, '...' )">
&nbsp;
            </p>

            <router-link :to="localizedRoute(`/posts/${featuredPost.slug}`)" class="blog-page--preview-link">
              Artikel ansehen&nbsp;
            </router-link>
          </div>
        </div>
      </div>

      <div class="blog-page--entries">
        <div class="container">
          <div class="blog-wrap">
            <post-tile v-for="(post) in postList" :key="post.id" :post="post" />
          </div>
        </div>
      </div>

      <!-- div class="blog-page--pager-container">
        <ul class="blog-page--pager">
          <li class="first"><a href="#"></a></li>
          <li><a href="#">1</a></li>
          <li><a href="#">2</a></li>
          <li><a href="#">3</a></li>
          <li class="dots"><a href="#">...</a></li>
          <li><a href="#">12</a></li>
          <li class="last"><a href="#"></a></li>
        </ul>
      </div -->
    </div>

    <div v-else>
      <h3>There are no posts.</h3>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PostTile from './PostTile'

export default {
  name: 'PostsIndex',
  components: {
    PostTile
  },
  serverPrefetch () {
    return this.fetchPosts()
  },

  // Client-side only
  mounted () {
    // If we didn't already do it on the server
    // we fetch the item (will first show the loading text)
    if (!this.posts.length) {
      this.fetchPosts()
    }
  },
  computed: {
    ...mapGetters({
      posts: 'wordpress/posts'
    }),

    postList () {
      return this.posts.slice(1)
    },

    featuredPost () {
      return this.posts.slice(0, 1)[0]
    }
  },
  filters: {
    truncate: function (text, length, suffix) {
      return text.length > 0 ? text.substring(0, length) + suffix : ''
    },

    prettyDate: function (date) {
      const newDate = new Date(date)
      const dateFormat = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      return newDate.toLocaleDateString('de-DE', dateFormat)
    }
  },
  methods: {
    fetchPosts () {
      return this.$store.dispatch('wordpress/loadPosts', { first: 40 })
    },
    getPostImage (post) {
      if (post.featuredImage != null && post.featuredImage.node.sourceUrl != null) {
        return {
          backgroundImage: `url(${post.featuredImage.node.sourceUrl})`
        }
      } else {
        return {
          backgroundColor: '#ccc'
        }
      }
    },
    getCategory (post) {
      if (typeof post.categories.edges[0] !== 'undefined') {
        return post.categories.edges[0].node.name;
      }
    }
  }
}
</script>
