<template>
  <section class="is--slider-categories-wide is--slider-specials">
    <div class="container">
      <h2 class="section-head">
        ZAUN24-Specials
      </h2>

      <div class="categories-box-wrap">
        <router-link
          class="categories-box box-express"
          :to="categoryLink(specialsLeft)"
          v-if="specialsLeft"
        >
          <div class="specials-box-content">
            <div>
              <div class="box-head">
                {{ specialsLeft.name }}
              </div>
              <div class="box-content">
                {{ specialsLeft.category_linktext || specialsLeft.name }}
              </div>
              <div class="box-actions">
                <span class="btn btn-white">Jetzt ansehen</span>
              </div>
            </div>
          </div>

          <div class="box-express-right">
            <div>
              <img
                class="badge"
                v-lazy="'/assets/logo/zaun24-ohne-betonieren.png'"
                alt="Zäune ohne Betonieren - Fundamente mit Bodenhülsen"
              >
            </div>
          </div>
        </router-link>

        <router-link
          class="categories-box box-discount"
          :to="categoryLink(specials)"
          v-if="specials"
        >
          <div class="specials-box-content">
            <img
              v-lazy="'/assets/icon/icon-discount.svg'" alt="Rabatt-Aktion"
            >
            <div class="box-head">
              <div>{{ specials.name }}</div>
            </div>
          </div>

          <div class="categories-box-image">
            <img
              v-lazy="'/assets/content/specials-discount.jpg'"
              alt="Unsere Zaun-Angebote"
            >
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import SliderCategories from './SliderCategories.vue'

export default {
  mixins: [SliderCategories],
  computed: {
    specialsLeft () {
      return this.findCategory(/betonieren/i)
    },
    specials () {
      return this.findCategory(/aktion|angebote/i)
    }
  },
  methods: {
    findCategory (search) {
      return this.loadedCategories.find((e) => {
        return e.name.match(search)
      })
    }
  }
}
</script>
