<template>
  <section class="is--intro-mobile visible-xs">
    <div class="container">
      <h1>
        Zäune einfach online kaufen ...<br>
        Vom Original - Seit 2008
      </h1>
    </div>
  </section>
</template>

<script>
export default {

}
</script>
