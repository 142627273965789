<template>
  <div>
    <div id="home" v-if="storeCode === 'zaun24_de'">
      <banner-slider :id="1" />
      <intro-mobile />

      <slider-categories
        :categories="[1157, 1159]"
        :preload="1157"
      />
      <slider-categories
        heading="Sichtschutz, Gabionen & mehr"
        :categories="[1160, 18522, 24150, 1161]"
      />
      <promo-zaun24 />

      <slider-categories
        heading="ZAUN24-Neuheiten"
        :categories="[21905, 26783, 26714, 25585]"
        :sort-by-prop="true"
      />

      <slider-categories
        heading="ZAUN24-Bestseller"
        :categories="[1284, 23633, 1362, 1382]"
        :sort-by-prop="true"
      />
      <section class="is--specials">
        <template lang="html" src="@storefront-ui/vue/src/components/molecules/SfSlidingSection/SfSlidingSection.html" /><!-- eslint-disable-line -->
      </section>
      <slider-specials
        :categories="[26537, 1163]"
      />
      <slider-blog />
    </div>

    <div id="home" v-if="storeCode === 'zaun24_at'">
      <banner-slider :id="2" />
      <intro-mobile />

      <slider-categories
        :categories="[23314, 19217]"
      />

      <slider-categories
        heading="Gabionen, Zubehör, Briefkästen & Mülltonnenboxen"
        :categories="[22070, 26482, 23320, 26415]"
        sort-by-prop="true"
      />
    </div>
  </div>
</template>

<script>
// query constructor
import { isServer, onlineHelper } from '@vue-storefront/core/helpers'
import LazyHydrate from 'vue-lazy-hydration'

// Core pages
import Home from '@vue-storefront/core/pages/Home'

// Theme core components
import IntroMobile from 'theme/components/core/blocks/Custom/IntroMobile'
import SliderCategories from 'theme/components/core/blocks/Custom/SliderCategories'
import PromoZaun24 from 'theme/components/core/blocks/Custom/PromoZaun24'
import SliderSpecials from 'theme/components/core/blocks/Custom/SliderSpecials'
import SliderBlog from 'theme/components/core/blocks/Custom/SliderBlog'

// Theme local components
import TileLinks from 'theme/components/theme/blocks/TileLinks/TileLinks'
import { Logger } from '@vue-storefront/core/lib/logger'
import { mapGetters, mapActions } from 'vuex'
import config from 'config'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { RecentlyViewedModule } from '@vue-storefront/core/modules/recently-viewed'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

import BannerSlider from 'src/modules/vsf-bannerslider/components/BannerSlider.vue'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

export default {
  data () {
    return {
      loading: true
    }
  },
  mixins: [Home],
  components: {
    BannerSlider,
    IntroMobile,
    SliderCategories,
    PromoZaun24,
    SliderSpecials,
    SliderBlog
  },
  computed: {
    ...mapGetters('user', ['isLoggedIn']),
    ...mapGetters('homepage', ['getEverythingNewCollection']),
    ...mapGetters('cart', ['getCartToken']),
    categories () {
      return this.getCategories
    },
    isOnline () {
      return onlineHelper.isOnline
    },
    isLazyHydrateEnabled () {
      return config.ssr.lazyHydrateFor.some(
        field => ['homepage', 'homepage.new_collection'].includes(field)
      )
    },
    storeCode () {
      return currentStoreView().storeCode
    }
  },
  beforeCreate () {
    registerModule(RecentlyViewedModule)
  },
  async beforeMount () {
    if (this.$store.state.__DEMO_MODE__) {
      const onboardingClaim = await this.$store.dispatch('claims/check', { claimCode: 'onboardingAccepted' })
      if (!onboardingClaim) { // show onboarding info
        this.$bus.$emit('modal-toggle', 'modal-onboard')
        this.$store.dispatch('claims/set', { claimCode: 'onboardingAccepted', value: true })
      }
    }
  },
  mounted () {
    if (!this.isLoggedIn && localStorage.getItem('redirect')) this.$bus.$emit('modal-show', 'modal-signup')

    if (window?.location?.pathname.match(/^\/customer\/account\/forgotpassword/)) {
      this.$store.commit('ui/setAuthElem', 'forgot-pass')
      EventBus.$emit('modal-show', 'modal-signup')
    }
    if (window?.location?.pathname.match(/^\/cart/)) {
      this.openMicrocart()
    }
  },
  watch: {
    isLoggedIn () {
      const redirectObj = localStorage.getItem('redirect')
      if (redirectObj) this.$router.push(redirectObj)
      localStorage.removeItem('redirect')
    }
  },
  async asyncData ({ store, route }) { // this is for SSR purposes to prefetch data
    Logger.info('Calling asyncData in Home (theme)')()
    await Promise.all([
      store.dispatch('bannerslider/list', { filterValues: [1, 2], filterField: 'slider_id', includeFields: ['slider_id', 'name', 'image', 'image_alt', 'click_url', 'button_text', 'caption', 'order_banner'] })
    ])
  },
  methods: {
    ...mapActions({
      openMicrocart: 'ui/toggleMicrocart'
    })
  }
}
</script>
