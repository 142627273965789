<template>
  <a
    href="https://www.ekomi.de/bewertungen-zaun24.de.html"
    target="_blank"
    class="badge badge-ekomi"
  >
    <img
      v-if="sealImg"
      v-lazy="sealImg"
      alt="Ekomi"
    >
  </a>
</template>

<script>
export default {
  name: 'EkomiSeal',
  data () {
    return {
      sealImg: null
    }
  },
  async mounted () {
    const timeout = 60 * 60 * 1000 // one hour

    try {
      const seal = JSON.parse(localStorage.getItem('ekomiSeal'))
      if (seal.time > (new Date()).getTime() - timeout) {
        this.sealImg = seal.img
      }
    } catch (e) {}

    if (!this.sealImg) {
      this.sealImg = await this.getSealImg()
      localStorage.setItem('ekomiSeal', JSON.stringify({
        img: this.sealImg,
        time: (new Date()).getTime()
      }))
    }
  },
  methods: {
    async getSealImg () {
      const rating = await this.fetchRating()
      return 'https://sw-assets.ekomiapps.de/resources/de_seal_' + this.getSealForReview(rating) + '-500.png'
    },
    async fetchRating () {
      const response = await fetch('https://smart-widget-assets.ekomiapps.de/data_files/json/910_seller_summary.json')
      const result = await response.json()
      return result.rating
    },
    ratingRange (e, t, a) {
      return e >= t && e < a
    },
    getSealForReview (e) {
      let seal = 'standard';
      if (this.ratingRange(e, 4.8, 5.1)) {
        seal = 'gold'
      }
      if (this.ratingRange(e, 4.45, 4.8)) {
        seal = 'silver'
      }
      if (this.ratingRange(e, 4, 4.45)) {
        seal = 'bronze'
      }
      return seal
    }
  }
}
</script>
